import React from 'react';
import PropTypes from 'prop-types';
import { useMedia } from '@src/lib/hooks';

import Contact from '../../components/contact';
import Hero from '../../components/hero';
import LanaContainer from './lanaContainer';
import Layout from '@components/layout';
import LinkButton from '@src/components/linkButton';

export default function ApplicationMissingPage() {
  const isMobile = useMedia(['(max-width: 896px)'], [true], false);
  return (
    <Layout path="/lana/befintligt-lan" newStyle>
      <Hero title="Ansökan saknas" breadcrumbs={[{ name: 'Hem', path: '/' }]} />
      <LanaContainer padded={isMobile}>
        <p
          className="text-large"
          style={{ textAlign: 'center', paddingBottom: 24 }}
        >
          Vi hittade ingen ansökan på ditt personnummer.
        </p>
        <LinkButton to="/ansok-om-lan" linkText="Påbörja ansökan" />
      </LanaContainer>
      <Contact hideFAQ />
    </Layout>
  );
}
ApplicationMissingPage.propTypes = {
  data: PropTypes.object,
};
