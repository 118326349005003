import React from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from './primaryButton';
import { Link } from 'gatsby-plugin-intl';

export default function LinkButton(props) {
  const { to, linkText, ...rest } = props;
  return (
    <Link to={to}>
      <PrimaryButton type="submit" {...rest}>
        {linkText}
      </PrimaryButton>
    </Link>
  );
}

LinkButton.propTypes = {
  to: PropTypes.string,
  linkText: PropTypes.string,
};
